import { v4 as uuidv4 } from 'uuid';
import { isFunction, isNil, isString } from 'lodash';

import { Methods } from '../enums/methods';
import { IWeMoWebJsBridge } from '../definition';
import { MockData } from './definition';
import PostGC from '../post/gc';
import { IJobGarbageCollection, Jobs, PostMessageReply } from '../post/definition';
import Job from './job';
import SubscribeGC from '../subscribe/gc';
import { IListenerGarbageCollection, Subscribed } from '../subscribe/definition';
import Listener from './listener';

const MockPostDelay = 1000 * 1;
const MockPostFrequency = 1000 * 1;

class Mock<PostActions extends unknown[], SubscribeActions extends unknown[]> {
  private jobGC: IJobGarbageCollection;
  private jobs: Jobs = {};
  private listenerGC: IListenerGarbageCollection;
  private subscribed: Subscribed = {};
  private inApp: boolean = false;

  constructor(
    private readonly actions: { post: PostActions; subscribe: SubscribeActions },
    public readonly mockData: MockData
  ) {
    this.jobGC = new PostGC(this.jobs);
    this.listenerGC = new SubscribeGC(this.subscribed);
  }

  isInApp() {
    return this.inApp;
  }

  async init(privateKey: string) {
    this.inApp = true;
    return 'MOCK_WEMO_JS_BRIDGE';
  }

  postMessage<ReceiveData>(
    ...args: Parameters<IWeMoWebJsBridge<PostActions, SubscribeActions>['postMessage']>
  ) {
    const self = this;
    const [request] = args;
    const { action } = request;
    const jobId = uuidv4();

    const promise = new Promise<ReceiveData>((resolve, reject) => {
      const mock = this.mockData[`${Methods.POST} ${action}`];
      if (isNil(mock)) {
        reject(new Error('Mock Data Not Found'));
        return;
      }

      const { data, error, delay } = mock;
      const job = new Job({ gc: this.jobGC, resolve, reject });
      this.jobs[jobId] = job;
      job.start();

      if (!this.actions.post.includes(action)) {
        job.finish();
        reject(new Error('Invalid Action'));
        return;
      }

      const delayId = setTimeout(() => {
        job.finish();
        if (isNil(error)) {
          resolve(data);
        } else {
          reject(error);
        }
      }, delay || MockPostDelay);
      job.setDelayId(delayId);
    });

    const cancel = () => {
      const job = this.jobs[jobId] as Job;
      if (isNil(job)) return;
      job.clearDelay();
      job.cancel();
    };

    return new Proxy(promise, {
      get(target: Promise<ReceiveData>, key: string, receiver: any) {
        if (key === 'cancel') return cancel.bind(self);
        if (key === 'jobId') return jobId;
        const prop = Reflect.get(target, key, receiver);
        if (isFunction(prop)) return prop.bind(target);
        return prop;
      },
    }) as PostMessageReply<ReceiveData>;
  }

  subscribeMessage(
    ...args: Parameters<IWeMoWebJsBridge<PostActions, SubscribeActions>['subscribeMessage']>
  ) {
    const [request, successCallback, failureCallback] = args;
    const { action } = request;

    if (!isString(action)) {
      failureCallback(new Error('Action Must Be String'));
      return;
    }

    if (!this.actions.subscribe.includes(action)) {
      failureCallback(new Error('Invalid Action'));
      return;
    }

    const mock = this.mockData[`${Methods.SUBSCRIBE} ${action}`];
    if (isNil(mock)) {
      failureCallback(new Error('Mock Data Not Found'));
      return;
    }

    const listenerId = uuidv4();
    const { data, error, delay, frequency, randomFn } = mock;
    const listener = new Listener({
      gc: this.listenerGC,
      action,
      success: successCallback,
      failure: failureCallback,
    });
    if (isNil(this.subscribed[action])) this.subscribed[action] = {};
    this.subscribed[action][listenerId] = listener;

    const work = () => {
      if (isNil(error)) {
        successCallback(data);
      } else {
        failureCallback(error);
      }
    };

    if (isFunction(randomFn) && delay) {
      const workWrapper = () => {
        const time = randomFn();
        if (time) {
          const id = setTimeout(() => {
            work();
            workWrapper();
          }, time);
          listener.setTimerId(id, 'TIMEOUT');
        } else {
          const id = setTimeout(() => {
            workWrapper();
          }, delay);
          listener.setTimerId(id, 'TIMEOUT');
        }
      };
      workWrapper();
    } else if (delay) {
      const id = setTimeout(work, delay);
      listener.setTimerId(id, 'TIMEOUT');
    } else {
      const id = setInterval(work, frequency || MockPostFrequency);
      listener.setTimerId(id, 'INTERVAL');
    }

    return listenerId;
  }

  unsubscribeMessage(
    ...args: Parameters<IWeMoWebJsBridge<PostActions, SubscribeActions>['unsubscribeMessage']>
  ) {
    const [request] = args;
    const { action, listenerId } = request;
    if (!isString(action)) throw new Error('Action Must Be String');
    const listen = this.subscribed[action][listenerId];
    if (isNil(listen)) return;
    listen.unsubscribe();
  }
}

export default Mock;
