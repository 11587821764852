import { isNil } from 'lodash';

import Job, { JobConstructor } from '../post/job';

class MockJob extends Job {
  private delayId: NodeJS.Timeout | undefined;

  constructor(props: JobConstructor) {
    super(props);
  }

  setDelayId(id: NodeJS.Timeout) {
    this.delayId = id;
  }

  clearDelay() {
    if (isNil(this.delayId)) return;
    clearTimeout(this.delayId);
  }
}

export default MockJob;
