import { isNil } from 'lodash';

import { Jobs } from './definition';

const IdleTimeout = 3000;

class JobGarbageCollection {
  private counter = 0;
  private idleTimer?: NodeJS.Timeout;
  private eventHandler?: () => void;

  constructor(private jobs: Jobs) {}

  private clear() {
    const jobIds = Object.keys(this.jobs);
    for (const jobId of jobIds) {
      const job = this.jobs[jobId];
      if (isNil(job)) continue;
      if (job.isFinished) {
        this.jobs[jobId] = undefined;
        delete this.jobs[jobId];
      }
    }
  }

  private clearIdleTimer() {
    if (isNil(this.idleTimer)) return;
    clearTimeout(this.idleTimer);
    this.idleTimer = undefined;
  }

  private startIdleTimer() {
    if (!isNil(this.idleTimer)) return;
    this.idleTimer = setTimeout(() => {
      this.clear();
      this.cancelIdleDetector();
    }, IdleTimeout);
  }

  private resetIdleTimer() {
    this.clearIdleTimer();
    this.startIdleTimer();
  }

  private startIdleDetector() {
    this.eventHandler = this.resetIdleTimer.bind(this);
    document.addEventListener('mousemove', this.eventHandler);
    document.addEventListener('touchmove', this.eventHandler);
    document.addEventListener('keypress', this.eventHandler);
    document.addEventListener('click', this.eventHandler);
    document.addEventListener('wheel', this.eventHandler);
    this.startIdleTimer();
  }

  private cancelIdleDetector() {
    if (isNil(this.eventHandler)) return;
    document.removeEventListener('mousemove', this.eventHandler);
    document.removeEventListener('touchmove', this.eventHandler);
    document.removeEventListener('keypress', this.eventHandler);
    document.removeEventListener('click', this.eventHandler);
    document.removeEventListener('wheel', this.eventHandler);
    this.eventHandler = undefined;
    this.clearIdleTimer();
  }

  plus() {
    this.counter++;
    this.cancelIdleDetector();
  }

  minus() {
    if (this.counter < 1) return;
    this.counter--;
    if (this.counter === 0) this.startIdleDetector();
  }
}

export default JobGarbageCollection;
