import { isNil } from 'lodash';

import { Subscribed } from './definition';

const IdleTimeout = 3000;

type Counter = { [action: string]: number };

class ListenerGarbageCollection {
  private counter: Counter = {};
  private idleTimer?: NodeJS.Timeout;
  private eventHandler?: () => void;

  constructor(private subscribed: Subscribed) {}

  private clear() {
    const actions = Object.keys(this.subscribed);
    for (const action of actions) {
      const listeners = this.subscribed[action];
      const listenerIds = Object.keys(listeners);
      for (const listenerId of listenerIds) {
        const listen = this.subscribed[action][listenerId];
        if (isNil(listen)) continue;
        if (listen.isUnsubscribed()) {
          this.subscribed[action][listenerId] = undefined;
          delete this.subscribed[action][listenerId];
        }
      }
    }
  }

  private clearIdleTimer() {
    if (isNil(this.idleTimer)) return;
    clearTimeout(this.idleTimer);
    this.idleTimer = undefined;
  }

  private startIdleTimer() {
    if (!isNil(this.idleTimer)) return;
    this.idleTimer = setTimeout(() => {
      this.clear();
      this.cancelIdleDetector();
    }, IdleTimeout);
  }

  private resetIdleTimer() {
    this.clearIdleTimer();
    this.startIdleTimer();
  }

  private startIdleDetector() {
    this.eventHandler = this.resetIdleTimer.bind(this);
    document.addEventListener('mousemove', this.eventHandler);
    document.addEventListener('touchmove', this.eventHandler);
    document.addEventListener('keypress', this.eventHandler);
    document.addEventListener('click', this.eventHandler);
    document.addEventListener('wheel', this.eventHandler);
    this.startIdleTimer();
  }

  private cancelIdleDetector() {
    if (isNil(this.eventHandler)) return;
    document.removeEventListener('mousemove', this.eventHandler);
    document.removeEventListener('touchmove', this.eventHandler);
    document.removeEventListener('keypress', this.eventHandler);
    document.removeEventListener('click', this.eventHandler);
    document.removeEventListener('wheel', this.eventHandler);
    this.eventHandler = undefined;
    this.clearIdleTimer();
  }

  plus(action: string) {
    if (isNil(this.counter[action])) {
      this.counter[action] = 1;
    } else {
      this.counter[action]++;
    }
    this.cancelIdleDetector();
  }

  minus(action: string) {
    if (isNil(this.counter[action])) return;
    if (this.counter[action] < 1) return;
    this.counter[action]--;
    if (this.counter[action] === 0 && isNil(this.eventHandler)) this.startIdleDetector();
  }
}

export default ListenerGarbageCollection;
