import { SuccessCallback, FailureCallback, IListenerGarbageCollection } from './definition';

export type ListenerConstructor = {
  action: string;
  success: SuccessCallback;
  failure: FailureCallback;
  gc: IListenerGarbageCollection;
};

class Listener {
  private action: string;
  private gc: IListenerGarbageCollection;
  private successCallback: SuccessCallback;
  private failureCallback: FailureCallback;
  protected subscribed: boolean;

  constructor(props: ListenerConstructor) {
    this.action = props.action;
    this.gc = props.gc;
    this.subscribed = true;
    this.successCallback = props.success;
    this.failureCallback = props.failure;

    this.gc.plus(this.action);
  }

  success(data?: any) {
    this.successCallback(data);
  }

  failure(error: Error) {
    this.failureCallback(error);
  }

  isUnsubscribed() {
    return !this.subscribed;
  }

  unsubscribe() {
    this.gc.minus(this.action);
    this.subscribed = false;
  }
}

export default Listener;
