import { v4 as uuidv4 } from 'uuid';
import { isNil, isString } from 'lodash';
import Bowser from 'bowser';
import Ajv, { JSONSchemaType, ValidateFunction } from 'ajv';

import { Methods } from '../enums/methods';
import {
  SubscribeMessage,
  PublishMessage,
  IListenerGarbageCollection,
  Subscribed,
  SubscribeRequest,
  UnsubscribeRequest,
  SuccessCallback,
  FailureCallback,
} from './definition';
import ListenerGarbageCollection from './gc';
import Listener from './listener';

const ajv = new Ajv();
const browser = Bowser.getParser(window.navigator.userAgent);
const ActionEmpty = '__ACTION_EMPTY__';

class WeMoWebJsBridgePost<Actions extends unknown[]> {
  private listenerGC: IListenerGarbageCollection;
  private subscribed: Subscribed = {};
  private actions: Actions;
  private subscribeJSONSchema: JSONSchemaType<SubscribeMessage>;
  private publishJSONSchema: JSONSchemaType<PublishMessage>;
  private subscribeValidator: ValidateFunction<SubscribeMessage>;
  private publishValidator: ValidateFunction<PublishMessage>;

  constructor(actions: Actions) {
    this.listenerGC = new ListenerGarbageCollection(this.subscribed);
    this.actions = actions;
    for (const action of actions) {
      if (!isString(action)) throw new Error('Action Must Be String');
      this.subscribed[action] = {};
    }
    this.subscribeJSONSchema = {
      type: 'object',
      properties: {
        method: { type: 'string', pattern: 'POST' },
        action: { type: 'string', enum: [ActionEmpty, ...(actions as string[])] },
        payload: { type: 'object', required: [], nullable: true },
      },
      required: ['action', 'method'],
    };
    this.publishJSONSchema = {
      type: 'object',
      properties: {
        action: { type: 'string', enum: [ActionEmpty, ...(actions as string[])] },
        result: { type: 'string', enum: ['success', 'failure'] },
        data: { type: 'object', required: [], nullable: true },
        error: { type: 'string', nullable: true },
      },
      required: ['action', 'result'],
      if: { properties: { result: { type: 'string', pattern: 'failure' } } },
      then: { required: ['error'] },
      additionalProperties: false,
    };
    this.subscribeValidator = ajv.compile(this.subscribeJSONSchema);
    this.publishValidator = ajv.compile(this.publishJSONSchema);
  }

  private postMessage<Payload>(data: {
    method: string;
    action: Actions[number];
    token?: string;
    payload?: Payload;
  }) {
    if (this.subscribeValidator(data)) {
      if (data.method === Methods.SUBSCRIBE) throw new Error(`Subscribe Message Error`);
      if (data.method === Methods.UNSUBSCRIBE) throw new Error(`Unsubscribe Message Error`);
    }
    const message = JSON.stringify(data);
    /**
     * Post message to App
     * iOS: window.webkit.messageHandlers.WeMoJsBridge
     * Android: window.WeMoJsBridge
     */
    switch (browser.getOSName()) {
      case 'iOS': {
        window.webkit.messageHandlers.WeMoJsBridge.postMessage(message);
        break;
      }
      case 'Android': {
        window.WeMoJsBridge.postMessage(message);
        break;
      }

      default: {
        throw new Error('Browser Not Support');
      }
    }
  }

  private isListenerEmpty(action: string) {
    const listeners = this.subscribed[action];
    if (isNil(listeners)) return true;
    return !Object.values(listeners).some(
      (listener) => !isNil(listener) && !listener.isUnsubscribed()
    );
  }

  subscribeMessage<Payload>(
    request: SubscribeRequest<Actions[number], Payload>,
    successCallback: SuccessCallback,
    failureCallback: FailureCallback
  ) {
    const { action, token, payload } = request;
    try {
      if (!isString(action)) throw new Error('Action Must Be String');
      if (this.isListenerEmpty(action))
        this.postMessage({ method: Methods.SUBSCRIBE, action, token, payload });
    } catch (error) {
      failureCallback(error);
      return;
    }

    const listenerId = uuidv4();
    if (isNil(this.subscribed[action])) this.subscribed[action] = {};
    this.subscribed[action][listenerId] = new Listener({
      gc: this.listenerGC,
      action,
      success: successCallback,
      failure: failureCallback,
    });
    return listenerId;
  }

  unsubscribeMessage<Payload>(request: UnsubscribeRequest<Actions[number], Payload>) {
    const { action, listenerId, token, payload } = request;
    if (!isString(action)) throw new Error('Action Must Be String');
    const listen = this.subscribed[action][listenerId];
    if (isNil(listen)) return;
    listen.unsubscribe();
    if (this.isListenerEmpty(action))
      this.postMessage({
        method: Methods.UNSUBSCRIBE,
        action,
        token,
        payload,
      });
  }

  publishMessage(message: string) {
    let data: any;
    try {
      data = JSON.parse(message);
    } catch (error) {
      throw error;
    }

    if (!this.publishValidator(data)) {
      const { action } = data;
      if (isNil(action)) throw new Error('Publish Missing Action');
      if (!isString(action)) throw new Error('Publish Action Wrong Type');
      const listeners = Object.values(this.subscribed[action]);
      for (const listener of listeners) {
        if (isNil(listener)) continue;
        if (listener.isUnsubscribed()) continue;
        listener.failure(new Error('Publish Data Invalid'));
      }
      return;
    }

    if (data.action === ActionEmpty) {
      throw new Error('Publish Invalid Action');
    }

    const listeners = Object.values(this.subscribed[data.action]);
    switch (data.result) {
      case 'success': {
        for (const listener of listeners) {
          if (isNil(listener)) continue;
          if (listener.isUnsubscribed()) continue;
          listener.success(data.data);
        }
        break;
      }
      case 'failure': {
        if (isNil(data.error)) break;
        for (const listener of listeners) {
          if (isNil(listener)) continue;
          if (listener.isUnsubscribed()) continue;
          listener.failure(new Error(data.error));
        }
        break;
      }
      default:
        throw new Error('received result error');
    }
  }

  unsubscribeAll() {
    const actionsListeners = Object.values(this.subscribed);
    for (const actionListeners of actionsListeners) {
      const listeners = Object.values(actionListeners);
      for (const listener of listeners) {
        if (isNil(listener)) continue;
        listener.unsubscribe();
      }
    }
  }
}

export default WeMoWebJsBridgePost;
