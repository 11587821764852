import JSEncrypt from 'jsencrypt';
import { isString } from 'lodash';

import { Methods } from './enums/methods';
import { IPost, ISubscribe, IWeMoWebJsBridge } from './definition';
import { PostRequest, PostMessageReply } from './post/definition';
import {
  SubscribeRequest,
  UnsubscribeRequest,
  SuccessCallback,
  FailureCallback,
} from './subscribe/definition';
import Post from './post';
import Subscribe from './subscribe';

declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        WeMoJsBridge: {
          postMessage(message: string): void;
        };
      };
    };
    WeMoJsBridge: {
      postMessage(message: string): void;
    };
    receiveMessage(message: string): void;
    publishMessage(message: string): void;
  }
}

const ExposeFunctions = ['receiveMessage', 'publishMessage'];

class WeMoWebJsBridge<PostActions extends unknown[], SubscribeActions extends unknown[]>
  implements IWeMoWebJsBridge<PostActions, SubscribeActions> {
  private inApp: boolean = false;
  private post: IPost<PostActions>;
  private subscribe: ISubscribe<SubscribeActions>;
  private strict: boolean;
  actions: { post: PostActions; subscribe: SubscribeActions };

  constructor(actions: { post: PostActions; subscribe: SubscribeActions }, strict: boolean = true) {
    for (const functionName of ExposeFunctions) {
      if (functionName in window) throw new Error(`Function ${functionName} exist`);
    }

    this.strict = strict;
    this.actions = actions;

    this.post = new Post(actions.post);
    this.subscribe = new Subscribe(actions.subscribe);
    window.receiveMessage = (message: string) => {
      this.post.receiveMessage.call(this.post, message);
    };
    window.publishMessage = (message: string) => {
      this.subscribe.publishMessage.call(this.subscribe, message);
    };

    window.addEventListener('beforeunload', () => {
      if (this.inApp) {
        this.subscribe.unsubscribeAll();
        this.post.cancelAll();
      }
    });
  }

  isInApp() {
    return this.inApp;
  }

  async init(privateKey: string) {
    if (!this.strict) {
      this.inApp = true;
      // TODO: 因為 App 還沒實作 INIT 所以不 Post Message
      return '';
    }

    const { encrypted } = await this.post.postMessage<{ encrypted: string }>({ action: 'INIT' });
    const decrypt = new JSEncrypt({});
    decrypt.setPrivateKey(privateKey);
    const token = decrypt.decrypt(encrypted);
    if (isString(token)) {
      this.inApp = true;
      return token;
    } else {
      throw new Error('Invalid Device');
    }
  }

  postMessage<ReceiveData>(
    request: PostRequest<PostActions[number]>,
    timeout?: number | 'none'
  ): PostMessageReply<ReceiveData> {
    if (!this.inApp) throw new Error('Unauthorized');
    return this.post.postMessage(request, timeout);
  }

  subscribeMessage<Payload>(
    request: SubscribeRequest<SubscribeActions[number], Payload>,
    successCallback: SuccessCallback,
    failureCallback: FailureCallback
  ): string | undefined {
    if (!this.inApp) throw new Error('Unauthorized');
    return this.subscribe.subscribeMessage(request, successCallback, failureCallback);
  }

  unsubscribeMessage<Payload>(request: UnsubscribeRequest<SubscribeActions[number], Payload>) {
    if (!this.inApp) throw new Error('Unauthorized');
    this.subscribe.unsubscribeMessage(request);
  }
}

export default WeMoWebJsBridge;
export * from './mock';
export { Methods };
export type { IWeMoWebJsBridge };
