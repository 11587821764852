import { isNil } from 'lodash';
import Listener, { ListenerConstructor } from '../subscribe/listener';

class MockListener extends Listener {
  private timerId: NodeJS.Timeout | undefined;
  private timerType: 'TIMEOUT' | 'INTERVAL' | undefined;

  constructor(props: ListenerConstructor) {
    super(props);
  }

  setTimerId(timerId: NodeJS.Timeout, timerType: 'TIMEOUT' | 'INTERVAL') {
    this.timerId = timerId;
    this.timerType = timerType;
  }

  unsubscribe() {
    super.unsubscribe();
    if (isNil(this.timerId) || isNil(this.timerType)) return;
    if (this.timerType === 'INTERVAL') clearInterval(this.timerId);
    else clearTimeout(this.timerId);
  }
}

export default MockListener;
