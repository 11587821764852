import { isNil, isNaN, isNumber } from 'lodash';

import { IJobGarbageCollection } from './definition';

export type JobConstructor = {
  resolve: (value?: any) => void;
  reject: (value?: any) => void;
  timeout?: number | 'none';
  gc: IJobGarbageCollection;
};

const DefaultTimeout = 1000 * 60 * 10;

class Job {
  private gc: IJobGarbageCollection;
  private timeout: number | 'none' = DefaultTimeout;
  private timeoutId: NodeJS.Timeout | undefined;
  resolve: JobConstructor['resolve'];
  reject: JobConstructor['reject'];
  isFinished = false;

  constructor(props: JobConstructor) {
    this.gc = props.gc;
    this.resolve = props.resolve;
    this.reject = props.reject;
    if (!isNil(props.timeout)) this.timeout = props.timeout;
  }

  private clearTimeout() {
    if (!isNil(this.timeoutId)) clearTimeout(this.timeoutId);
  }

  private gcPlus() {
    if (this.timeout === 'none') return;
    this.gc.plus();
  }

  private gcMinus() {
    if (this.timeout === 'none') return;
    this.gc.minus();
  }

  start(timeout?: number | 'none') {
    if (!isNil(timeout) && !isNaN(timeout)) this.timeout = timeout;

    this.gcPlus();
    if (isNumber(this.timeout)) {
      this.timeoutId = setTimeout(() => {
        this.gcMinus();
        this.isFinished = true;
        if (this.reject) this.reject(new Error('Timeout'));
      }, this.timeout);
    }
  }

  finish() {
    if (this.isFinished) throw new Error('Already done');
    this.clearTimeout();
    this.isFinished = true;
    this.gcMinus();
  }

  cancel() {
    if (this.isFinished) return;
    this.clearTimeout();
    this.isFinished = true;
    this.reject(new Error('Cancel'));
    this.gcMinus();
  }
}

export default Job;
