import { v4 as uuidv4 } from 'uuid';
import { isFunction, isNil } from 'lodash';
import Bowser from 'bowser';
import Ajv, { JSONSchemaType, ValidateFunction } from 'ajv';

import {
  Jobs,
  IJobGarbageCollection,
  PostRequest,
  PostMessage,
  ReceiveMessage,
  PostMessageReply,
  PostProgram,
} from './definition';
import Job from './job';
import JobGarbageCollection from './gc';
import { Methods } from '../enums/methods';

const ajv = new Ajv();
const browser = Bowser.getParser(window.navigator.userAgent);

class WeMoWebJsBridgePost<Actions extends unknown[]> {
  private jobs: Jobs = {};
  private jobGC: IJobGarbageCollection;
  private actions: Actions;
  private postJSONSchema: JSONSchemaType<PostMessage>;
  private receiveJSONSchema: JSONSchemaType<ReceiveMessage>;
  private postValidator: ValidateFunction<PostMessage>;
  private receiveValidator: ValidateFunction<ReceiveMessage>;

  constructor(actions: Actions) {
    this.jobGC = new JobGarbageCollection(this.jobs);
    this.actions = actions;
    this.postJSONSchema = {
      type: 'object',
      properties: {
        method: { type: 'string', pattern: 'POST' },
        jobId: { type: 'string' },
        action: { type: 'string', enum: ['INIT', ...(actions as string[])] },
        token: { type: 'string', nullable: true },
        payload: { type: 'object', required: [], nullable: true },
      },
      required: ['action', 'jobId', 'method'],
    };
    this.receiveJSONSchema = {
      type: 'object',
      properties: {
        jobId: { type: 'string' },
        action: { type: 'string', enum: ['INIT', ...(actions as string[])] },
        result: { type: 'string', enum: ['success', 'failure'] },
        data: { type: 'object', required: [], nullable: true },
        error: { type: 'string', nullable: true },
      },
      required: ['action', 'jobId', 'result'],
      if: { properties: { result: { type: 'string', pattern: 'failure' } } },
      then: { required: ['error'] },
      additionalProperties: false,
    };
    this.postValidator = ajv.compile(this.postJSONSchema);
    this.receiveValidator = ajv.compile(this.receiveJSONSchema);
  }

  private execute<ReceiveData>(
    request: PostRequest<Actions[number] | 'INIT'>,
    program: PostProgram<ReceiveData>,
    timeout?: number | 'none'
  ) {
    const self = this;
    const jobId = uuidv4();

    const promise = new Promise<ReceiveData>((resolve, reject) => {
      const data = {
        method: Methods.POST,
        jobId,
        ...request,
      };

      if (!this.postValidator(data)) {
        reject(new Error('Post Data Error'));
        return;
      }

      const job = new Job({ gc: this.jobGC, resolve, reject, timeout });
      this.jobs[jobId] = job;
      job.start();

      program(job, data, resolve, reject);
    });

    const cancel = () => {
      const job = this.jobs[jobId];
      if (isNil(job)) return;
      job.cancel();
    };

    return new Proxy(promise, {
      get(target: Promise<ReceiveData>, key: string, receiver: any) {
        if (key === 'cancel') return cancel.bind(self);
        if (key === 'jobId') return jobId;
        const prop = Reflect.get(target, key, receiver);
        if (isFunction(prop)) return prop.bind(target);
        return prop;
      },
    }) as PostMessageReply<ReceiveData>;
  }

  postMessage<ReceiveData>(
    request: PostRequest<Actions[number] | 'INIT'>,
    timeout?: number | 'none'
  ) {
    return this.execute<ReceiveData>(
      request,
      (job, data, resolve, reject) => {
        /**
         * Post message to App
         * iOS: window.webkit.messageHandlers.WeMoJsBridge
         * Android: window.WeMoJsBridge
         */
        const message = JSON.stringify(data);
        switch (browser.getOSName()) {
          case 'iOS': {
            try {
              window.webkit.messageHandlers.WeMoJsBridge.postMessage(message);
            } catch (error) {
              job.finish();
              reject(new Error('iOS Post Error'));
            }
            break;
          }
          case 'Android': {
            try {
              window.WeMoJsBridge.postMessage(message);
            } catch (error) {
              job.finish();
              reject(new Error('Android Post Error'));
            }
            break;
          }
          default: {
            job.finish();
            reject(new Error('Browser Not Support'));
            break;
          }
        }
      },
      timeout
    );
  }

  receiveMessage(message: string) {
    let data: any;
    try {
      data = JSON.parse(message);
    } catch (error) {
      throw error;
    }

    if (!this.receiveValidator(data)) {
      if (isNil(data.jobId)) throw new Error('Missing Job ID');
      const job = this.jobs[data.jobId];
      // 可能 Timeout 後才 receive
      if (isNil(job)) return;
      const { reject } = job;
      job.finish();
      reject(new Error('Receive Data Error'));
      return;
    }

    const job = this.jobs[data.jobId];
    // 可能 Timeout 後才 receive
    if (isNil(job)) return;
    job.finish();

    const { resolve, reject } = job;
    switch (data.result) {
      case 'success': {
        resolve(data.data);
        break;
      }
      case 'failure': {
        if (isNil(data.error)) break;
        reject(new Error(data.error));
        break;
      }
      default:
        reject(new Error('Received Result Error'));
        break;
    }
  }

  cancelAll() {
    const jobs = Object.values(this.jobs);
    for (const job of jobs) {
      if (isNil(job)) continue;
      job.cancel();
    }
  }
}

export default WeMoWebJsBridgePost;
