import { v4 as uuidv4 } from 'uuid';
import { isError, isFunction, isNil, isString } from 'lodash';
import VConsole, { VConsolePluginInstance } from 'vconsole';

import { Methods } from '../enums/methods';
import Mock from './core';
import { MockData } from './definition';
import { IWeMoWebJsBridge } from '../definition';

export type WeMoJsBridgeMockData = MockData;

export function setMock<PostActions extends unknown[], SubscribeActions extends unknown[]>(
  instance: IWeMoWebJsBridge<PostActions, SubscribeActions>,
  mockData: MockData
) {
  const mock = new Mock(instance.actions, mockData);
  return new Proxy(instance, {
    get(target, key: string, receiver: any) {
      let prop = Reflect.get(mock, key, mock);
      if (!isNil(prop)) {
        if (isFunction(prop)) return prop.bind(mock);
        return prop;
      }

      prop = Reflect.get(target, key, receiver);
      if (!isNil(prop)) {
        if (isFunction(prop)) return prop.bind(target);
        return prop;
      }
      return;
    },
  });
}

export function createVConsolePlugin<
  PostActions extends unknown[],
  SubscribeActions extends unknown[]
>(
  instance: IWeMoWebJsBridge<PostActions, SubscribeActions>,
  mockData: MockData
): [IWeMoWebJsBridge<PostActions, SubscribeActions>, VConsolePluginInstance] {
  let mockInstance = setMock(instance, mockData);
  type SubscribeMessageParams = Parameters<typeof mockInstance.subscribeMessage>;
  type UnsubscribeMessageParams = Parameters<typeof mockInstance.unsubscribeMessage>;
  type SuccessCallback = SubscribeMessageParams[1];
  type FailureCallback = SubscribeMessageParams[2];
  const subscribed: {
    [action: string]: {
      data: any;
      error?: Error;
      listeners: {
        [listener: string]:
          | { successCallback: SuccessCallback; failureCallback: FailureCallback }
          | undefined;
      };
    };
  } = {};

  // @ts-ignore
  const plugin = new VConsole.VConsolePlugin(
    'WEMO_JS_BRIDGE',
    'WeMoJsBridge'
  ) as VConsolePluginInstance;

  for (const key in mockData) {
    const [method, action] = key.split(' ');
    if (method === Methods.POST) continue;
    if (method === Methods.UNSUBSCRIBE) continue;
    const { data, error } = mockData[key];
    subscribed[action] = { data, error, listeners: {} };
  }

  mockInstance = new Proxy(mockInstance, {
    get(target, key: string, receiver: typeof mockInstance) {
      if (!['subscribeMessage', 'unsubscribeMessage'].includes(key))
        return Reflect.get(target, key, receiver);

      const origin = Reflect.get(target, key, receiver);

      if (key === 'subscribeMessage') {
        return function (...args: SubscribeMessageParams) {
          const [request, successCallback, failureCallback] = args;
          const { action } = request;
          const mock = mockData[`${Methods.SUBSCRIBE} ${action}`];
          if (isNil(mock)) {
            failureCallback(new Error('Mock Data Not Found'));
            return;
          }

          if (!isString(action)) {
            failureCallback(new Error('Action Must Be String'));
            return;
          }

          if (!mockInstance.actions.subscribe.includes(action)) {
            failureCallback(new Error('Invalid Action'));
            return;
          }

          const listenerId = mock.manual ? uuidv4() : origin(...args);
          subscribed[action].listeners[listenerId] = { successCallback, failureCallback };
          return listenerId;
        }.bind(target);
      }

      if (key === 'unsubscribeMessage') {
        return function (...args: UnsubscribeMessageParams) {
          const [request] = args;
          const { action, listenerId } = request;
          if (!isString(action)) throw new Error('Action Must Be String');

          const mock = mockData[`${Methods.SUBSCRIBE} ${action}`];
          if (isNil(mock)) {
            throw new Error('Mock Data Not Found');
          }

          subscribed[action].listeners[listenerId] = undefined;
          if (!mock.manual) origin(...args);
        }.bind(target);
      }
    },
  });

  plugin.on('renderTab', function (callback) {
    // @ts-ignore
    const { render, delegate } = plugin.vConsole.$;
    const element = render(
      `
        <div style="padding: 8px;">
        {{for (const action of actions)}}
          <button class="action" style="min-width: 80px; height: 28px; margin: 8px;" data-action="{{action}}">{{action}}</button>
        {{/for}}
        </div>
      `,
      { actions: Object.keys(subscribed) }
    );

    delegate(element, 'click', '.action', function (event: MouseEvent) {
      const button = event.target as HTMLButtonElement;
      const action = button.getAttribute('data-action');
      if (!isString(action)) return;
      const { data, error, listeners } = subscribed[action];
      const listenerIds = Object.keys(listeners);
      for (const listenerId of listenerIds) {
        const job = listeners[listenerId];
        if (isNil(job)) continue;
        if (isError(error)) job.failureCallback(error);
        else job.successCallback(data);
      }
    });

    callback(element);
  });

  return [mockInstance, plugin];
}
